import SliderComponent from './SliderComponent'

export default function AnnounceComponent({data,shadowText,title,description}) {
  return (
<div id={shadowText === "Duyurular" ? "announcements" : shadowText === "Haberler" ? "news" : shadowText === "Etkinlikler" ? "activity" : "" } className=" mb-20 relative ">
          <div className="mx-10 flex justify-center items-center flex-col">
            <h1 className="text-components-title absolute  top-0 -z-[1] font-extrabold leading-relaxed 2xl:text-9xl xl:text-8xl lg:text-8xl text-[3rem] 2xl:mt-0 xl:mt-0 lg:mt-0 mt-2">{shadowText}</h1>
            <h1 className="text-components-title relative  font-extrabold leading-relaxed text-center 2xl:text-4xl xl:text-4xl lg:text-4xl text-2xl 2xl:mt-12 xl:mt-8 lg:mt-8 mt-8"> {title}</h1>
            <p className=" text-components-title font-bold mt-6 text-center text-sm">{description}</p>
          </div>
         <SliderComponent data={data}/>
    </div>
  )
}
