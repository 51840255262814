import {ReactComponent as RightIcon} from "../assets/right-icon.svg"
function CustomLeftArrow(props) {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
   <div className={`
   absolute  2xl:-right-28 xl:-right-28 lg:-right-28 -right-8 top-1/2 cursor-pointer
   ${isSafari && "2xl:-right-48 xl:-right-48 lg:-right-48"}
   `}>
     <RightIcon className="2xl:w-full xl:w-full lg:w-full md:w-12 sm:w-10  w-10" onClick={props.onClick}></RightIcon>
   </div>
  )
}

export default CustomLeftArrow