import React from 'react';
import { Routes, Route,  } from 'react-router-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import AboutPage from './pages/About';
import DetailPage from './pages/Detail';
import HomePage from './pages/Home';
import SssPage from './pages/Sss';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';

export default function AppRouter() {
  return (
   <>
      <Router>
        <HeaderComponent/>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/detail" element={<DetailPage />} />
            <Route path="/sss" element={<SssPage />} />
          </Routes>
        <FooterComponent/>
      </Router>

   </>
  );
}
