
import BoxComponent from "../components/BoxComponent"
import ContainerComponent from "../components/ContainerComponent";

const About = () => {
    const containerInformation = [
        {
            id:1,
            title: 'Tükettiğiniz Gıdanın Hikâyesini Bilmek İster Misiniz?”',
            description:
              'İstanbul Kalkınma Ajansı’nın 2021 yılı Yenilikçi İstanbul Mali Destek Programı kapsamında hibe almaya hak kazanan “Topraktan Fileye Gıda İzleme Modeli” ile filenize giren ürünlerin hikayesine ulaşmanız artık çok daha kolay. Model uygulama olarak Kadıköy Üretici ve Kooperatif Pazarında başlanan proje ile dijital dönüşüm ile gıda izlenebilirliği sağlanıyor.',
            img: '/why.png',
            bgText:'',
            componentPosition:'left',
            closeButton: true,
          },
          {
            id:2,
            title: 'Proje Kimler Tarafından Ve Nasıl Yürütülüyor?',
            description:'İstanbul Kalkınma Ajansı’nın 2021 yılı Yenilikçi İstanbul Mali Destek Programı kapsamında hibe almaya hak kazanan “Topraktan Fileye Gıda İzleme Modeli” kapsamında hayata geçirilen projenin liderliği İstanbul Büyükşehir Belediyesi Tarımsal Hizmetler Dairesi Başkanlığı tarafından yürütülmekte, İstanbul Üniversitesi ise proje ortağı olarak yer almaktadır. Kadıköy Üretici ve Kooperatif Pazarı’nın işletmesini yapan İSYÖN A.Ş. ise proje iştiraki olarak projede yer almaktadır. Projenin dijital dönüşüm, arazi kontrol, yazılım, donanım, bilgi işlem, arazi kontrol, veri kayıt ve işleme, denetleme, yönetim, yürütme ve sonuçlandırma işleri İstanbul Büyükşehir Belediyesi uhdesinde gerçekleştirilmektedir.',
            img: '/objective.png',
            bgText:'',
            componentPosition:'right',
            closeButton: true,
          },
        {
            id: 3,
            title: 'Gıda Kalite Ve Güvenliği Analizleri Nasıl Gerçekleştiriliyor?',
            description: 'Gıda Kalite ve güvenliği analizleri İstanbul Üniversitesi uhdesinde Bitki ve Bitkisel Ürünler Uygulama ve Araştırma Merkezinde gerçekleştirilmektedir. Günümüzde birçok hastalığın, sağlıksız gıda kökenli ya da ilişkili olduğu birçok akademik yayınla desteklenen bir olgu halini almıştır. Bu yaklaşımdan hareketle sağlıklı gıda tüketimi için belirleyici 2 temel alan üzerinde durulmaktadır. Bunlardan ilki tüketicilerin gıda tüketiminde bir gıda ürününün kalitesini belirleyen primer (karbonhidrat, protein, lipid) ve sekonder metabolitler (antioksidanlar) gıda kalitesini belirlemede analiz edilen en temel prensipler arasında yer almaktadır. Gıda güvenliği alanında ise insan sağlığı açısından toksik olarak nitelendirilen ağır metaller, pestisitler ve polisiklik aromatik hidrokarbonların analizleri gerçekleştirilmesi gereken bir diğer alandır.',
            img: '/why.png',
            bgText: '',
            componentPosition: 'left',
            closeButton: true,
        },
    ];
    const boxDatas = [
        {
            id: 1,
            title: 'GIDADA İZLENİLEBİLİRLİK',
            description: 'Güvenli gıdaya erişimde; üretim, işleme, taşıma, satış gibi aşamaların kontrol altında tutulduğu ve takip edilebildiği sistemdir.',
        },
        {
            id: 2,
            title: 'AMAÇ',
            description: 'İstanbul Büyükşehir Belediyesi tarafından 2020 yılında ilk kez “Kadıköy Üretici ve Kooperatif Pazarı” adıyla hayata geçirilen ve İstanbul’da tarımsal üretim yapan çiftçilerin ürünlerini, aracısız bir şekilde kendileri tarafından satışına olanak sağlayan üretici pazarı modelinde dijital ve izlenilebilir sistem kurmak.',
        },
        {
            id: 3,
            title: 'HEDEF',
            description: 'Topraktan Fileye Gıda İzleme Modeli’nin izlenebilirlik yönüyle diğer pazarlar için bir model olmasını sağlamak, tüketicilerin gıda seçimlerinde gıda kalitesi ve güvenliğini de ele alan kriterlere dikkat çekmelerini sağlamak.',
        },
    ];

    return (
        <div className='w-full h-full'>
            <div className='w-full flex items-center justify-center relative h-[35vh] px-4'>
                <div className=" h-full w-full absolute about-bg bg-opacity-50 -mt-24 flex flex-col"></div>
                <h1 className='2xl:text-6xl xl:text-6xl lg:text-6xl text-3xl  font-extrabold text-center text-components-title'>Hakkımızda</h1>
            </div>
            <div>
                {containerInformation.map((item, index) => (<ContainerComponent containerInformation={item} key={index}></ContainerComponent>))}
            </div>
            <div className="flex flex-col px-2 gap-4 mx-2 2xl:flex-row  xl:flex-row lg:flex-row my-20  2xl:mx-10 xl:mx-10  lg:mx-10">
                {boxDatas.map((item, index) => (<BoxComponent title={item.title} description={item.description} key={index}></BoxComponent>))}
            </div>
        </div>
    )
}

export default About