import React from 'react'
import { useState } from 'react';
import SssNumberComponent from './SssNumberComponent';
import {ReactComponent as SssRightArrow} from "../assets/sss-right-arrow.svg"
import SssClose from "../components/SssCloseComponent"
function SssItemComponent({data}) {
    const [clickable, setClickable] = useState(false);
    data.clickable=false;
    const ItemClick = () => {
        setClickable(!clickable);
      }
  return (
    <div>
        <div className="flex my-6 items-start justify-start 2xl:gap-4 xl:gap-4 lg:gap-4 gap-1">
          <div className="relative inline-block">
            <SssNumberComponent color={`${clickable === true ? "#d9d9d9" : "#408320"} `} className="2xl:w-[80px] xl:w-[80px] lg:w-[80px] w-[70%] h-10"/>
            <p className={`${clickable === true ? "text-components-title" : "text-white"}  absolute top-1/2 2xl:left-1/2 xl:left-1/2 lg:left-1/2 left-5 transform -translate-x-1/2 -translate-y-1/2 2xl:text-5xl xl:text-5xl lg:text-5xl text-lg  font-bold`}>{data.id}</p>
          </div>
          <div className={`${clickable && "bg-sss-item-bg-secondary text-white"} bg-sss-item-bg-primary w-full h-full 2xl:p-5 xl:p-5 lg:p-5 p-[0.30rem] rounded-md flex items-center`}>
              {
                   clickable === false && 
                <div className="w-full flex justify-between items-center cursor-pointer "  onClick={ItemClick}>
                <p className="2xl:text-base xl:text-base lg:text-base text-[9px]  font-bold">{data.title}</p>
                    <SssRightArrow onClick={ItemClick} className="2xl:w-[40px] xl:w-[40px] lg:w-[40px] cursor-pointer"></SssRightArrow>
               
            </div> }
                {
                    clickable === true &&
                         <div className='flex justify-between cursor-pointer w-full h-full items-start' onClick={ItemClick}>
                                {/* <p className='2xl:w-[95%] xl:w-[95%] lg:w-[95%] w-[85%] 2xl:text-base xl:text-base lg:text-base text-[9px]'>
                                Güvenli gıdaya erişimde; üretim, işleme, 
                                taşıma, satış gibi aşamaların kontrol 
                                altında tutulduğu ve takip edilebildiği 
                                sistemdir.
                                Üretimden satışa kadar tüm aşamaları içermektedir
                
                                İzlenebilirlik sisteminin üç temel unsuru bulunmaktadır:
                                <ul className='list-decimal pl-4'>
                                  <li>
                                   Tüm ürün ve girdilerin, birim veya partilerinin tanımlanması
                               
                                  </li>
                                  <li>
                                   Bunların nereden, ne zaman ve nereye hareket ettiklerine ilişkin 
                                bilginin toplanması ve saklanması
                                  </li>
                                  <li>
                                 Bu iki veriyi birbiri ile ilişkilendirecek bir sistemin kurulmasıdır.

                                  </li>
                                </ul>
                            </p>> */}
                            <p className='2xl:w-[95%] xl:w-[95%] lg:w-[95%] w-[85%] 2xl:text-base xl:text-base lg:text-base text-[9px] pb-4'>
                            {data.description}</p>
                            <div className='relative 2xl:w-[5%] xl:w-[5%] lg:w-[5%] w-[15] ' >
                              <div onClick={ItemClick} className='inline-block absolute right-0 '><SssClose color={"white"} textColor={"rgba(0, 67, 140, 1)"} ></SssClose></div>
                            </div>
                         </div>
                }
          </div>
        </div>
    </div>
  )
}

export default SssItemComponent