import {ReactComponent as GoogleStore} from "../assets/PlayStore/google-play.svg"
import {ReactComponent as AppStore} from "../assets/PlayStore/app-store.svg"
import { Link } from "react-router-dom";
export default function MobilePromotionComponent() {
  const mobileImage='/telephone.png';
  return (
    <div>
      <div className="bg-mobile-promotion-components-bg w-full grid grid-cols-12 relative mb-20 ">
      <div className="2xl:col-span-7 xl:col-span-7 col-span-12 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 -mt-20 lg:col-span-6 md:col-span-6 sm:col-span-6 relative 2xl:top-0 xl:top-0 lg:top-0 md:top-0 sm:top-0 2xl:pt-10 xl:pt-10  lg:pt-10  md:pt-10  sm:pt-10 2xl:text-start xl:text-start lg:text-start  md:text-start  sm:text-start text-center text-white p-10 2xl:order-1 xl:order-1 lg:order-1 order-2">
        <h1 className=" font-extrabold 2xl:text-4xl xl:text-3xl lg:text-2xl mb-6 !leading-normal 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 mt-20">Topraktan Fileye  Gıda İzleme Modeli artık “İstanbul Senin” de</h1>
        <p className="font-medium 2xl:text-xl xl:text-xl lg:text-lg md:text-base sm:text-sm text-sm mb-6">İstanbul Senin uygulamasını aşağıdan indirebilirsiniz.</p>
        <div className="flex 2xl:gap-4 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 gap-0 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row 2xl:justify-start xl:justify-start lg:justify-start md:justify-start sm:justify-start justify-center items-center flex-col">
          <Link to="https://play.google.com/store/apps/details?id=com.tr.gov.ibb.istanbulsenin&hl=en_AU&gl=TR" target='_blank' rel="noreferrer">
            <GoogleStore></GoogleStore>
          </Link>
          <Link to="https://apps.apple.com/tr/app/lstanbul-senin/id1534342254?l=tr" target='_blank' rel="noreferrer">
            <AppStore></AppStore>
          </Link>
        </div>
      </div>
   <div className="relative z-0 w-full h-[120%] flex items-center justify-center top-1/2 inner-div  2xl:col-span-5 xl:col-span-5 lg:col-span-6 
   md:col-span-6 sm:col-span-6 col-span-12 2xl:order-2 xl:order-2 lg:order-2 md:order-2 sm:order-2 order-1">
   <img src="/telephone.png" alt="phone" className="2xl:flex xl:flex lg:flex md:flex sm:flex hidden
       z-10 2xl:absolute xl:absolute lg:absolute md:absolute sm:absolute relative md:top-0  sm:top-10 -top-20   right-0  p-0 "></img>

    <img src={mobileImage} alt="phone" className="2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden order-1 
    relative -top-0 w-full left-0 h-[100%] 
    " width={500} height={500}/>  
   </div>
    </div>
    </div>

  )
}

