import {ReactComponent as LeftIcon} from "../assets/left-icon.svg"
export default function CustomLeftArrow(props) {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <div className={`
    absolute 2xl:-left-28 xl:-left-28 lg:-left-28 -left-8 top-1/2 cursor-pointer
    ${isSafari && "2xl:-left-48 xl:-left-48 lg:-left-48"}
    `} >
      <LeftIcon className="2xl:w-full xl:w-full lg:w-full md:w-12 sm:w-10 w-10" onClick={props.onClick}></LeftIcon>
    </div>
  )
}