import {ReactComponent as Facebook} from "../assets/SocialMedia/facebook.svg"
import {ReactComponent as Linkedln} from "../assets/SocialMedia/linkedln.svg"
import {ReactComponent as Twitter} from "../assets/SocialMedia/twitter.svg"
import {ReactComponent as Instagram} from "../assets/SocialMedia/instagram.svg"
import { Link } from "react-router-dom"
export default function FollowUsComponent() {
  return (
    <div className='flex flex-col justify-start 2xl:items-start xl:items-start lg:items-start items-center mt-6'>
                    <p className='font-bold text-sm'>Bizi Takip Edin</p>
                    <div className='flex gap-1 mt-1'>
                        <Link target='_blank' to="https://tr-tr.facebook.com/">
                            <Facebook></Facebook>
                        </Link>
                        <Link target='_blank' to="https://www.linkedin.com/company/topraktanfileye">
                            <Linkedln></Linkedln>
                        </Link>
                        <Link target='_blank' to="http://x.com/topraktanfileye">
                            <Twitter></Twitter>
                        </Link>
                        <Link target='_blank' to="https://www.instagram.com/topraktanfileye/">
                            <Instagram></Instagram>
                        </Link>
                    </div>
            </div>
  )
}
