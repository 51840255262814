import {ReactComponent as DownArrow} from "../assets/down-arrow.svg"

export default function DiagramItemComponent({data}) {
  return (
    <div className={`${data.order} 2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6 `}>
    <div className="flex justify-between 2xl:h-[26rem] xl:h-[28rem] lg:h-[30rem] md:h-[23rem] sm:h-[20rem] h-[18rem]  bg-diagram-box-bg  items-center flex-col rounded-xl px-4 2xl:mb-28 xl:mb-40 lg:mb-40 mb-0 mt-10">
        <div className=" flex justify-center items-start 2xl:h-[20%] xl:h-[20%] lg:h-[2%] md:h-[50%] sm:h-[40%]  2xl:mb-0 xl:mb-2 lg:mb-0 ">
        <img src={`Numbers/${data?.id}.svg`} className="w-1/2  2xl:-mt-14  xl:-mt-14 lg:-mt-10 2xl:mb-4 xl:mb-4 lg:mb-4 md:-mt-14 sm:-mt-12 -mt-6 mb-3 fill-info-card-bg" alt="" />
        </div>
        <h1 className="font-bold 2xl:text-3xl flex justify-center flex-col items-center xl:text-2xl lg:text-xl text-sm text-components-title w-full text-center 2xl:mb-4 xl:mb-4  mb-2 2xl:mt-4 mt-2">
            {data.title}
            <hr className='bg-black w-[75%]  h-[2px] mt-4 mb-4'/>    
        </h1>
       
       <div className="h-[70%]">
       <p className="  text-center 2xl:mb-8 xl:mb-8 lg:mb-8 mb-4 text-components-title font-medium 2xl:text-base xl:text-base lg:text-base md:text-base sm:text-sm text-[9px]">{data.description}</p>
       </div>
    </div>
       {
        data.id === 2 && 
        <div className="w-full flex justify-center items-center 2xl:hidden xl:hidden lg:hidden mt-2">
            <DownArrow></DownArrow>
        </div>
       }
       {
        data.id === 4 && 
        <div className='relative'>
            <div className="w-full flex justify-center items-center 2xl:hidden xl:hidden lg:hidden mt-2">
                <DownArrow></DownArrow>
            </div>
            <div className="w-full h-full justify-center items-center 2xl:flex xl:flex lg:flex hidden absolute -top-16  ">
                <DownArrow></DownArrow>
            </div>
        </div>
       }
       {
        data.id === 6 && 
        <div className="w-full flex justify-center items-center 2xl:hidden xl:hidden lg:hidden mt-2">
            <DownArrow></DownArrow>
        </div>
       }
       {
        data.id === 8 && 
        <div className='relative'>
            <div className="w-full flex justify-center items-center 2xl:hidden xl:hidden lg:hidden mt-2">
                <DownArrow></DownArrow>
            </div>
            <div className="w-full h-full justify-center items-center 2xl:flex xl:flex lg:flex hidden absolute -top-16  ">
                <DownArrow></DownArrow>
            </div>
        </div>
       }
    </div>
  )
}