import DiagramItemComponent from "../components/DiagramItemComponent.js"
export default function DiagramComponent(){
    const data = [
        {
            id:1,
            title:'Bilgilendirme',
            description:'Topraktan Fileye Gıda İzleme Modelinin pazarda yer alan üreticilere tanıtımı yapılarak projeye katılımlarının sağlanması yönünde teşvik edilecek.',
            order:"order-1 2xl:order-1 xl:order-1 lg:order-1"
        },
        {
            id:2,
            title:'Kayıt',
            description:'Projede yer almak isteyen üreticilerin “Üretici Bilgi Formlarına” gıda izleme uzmanları tarafından bilgileri kaydedilecek.',
            order:"order-2 2xl:order-2 xl:order-2 lg:order-2"
        },
        {
            id:3,
            title:'Kontrol',
            description:'Projeye katılım yönünden uygunluk taşıyan üreticiler üretim yaptıkları arazide ziyaret edilecek ve arazi kontrolleri sağlanacaktır.',
            order:"order-4 2xl:order-3 xl:order-3 lg:order-3"
        },
        {
            id:4,
            title:'Gıda Kalite ve Güvenliği',
            description:'Arazi kontrolleri sırasında, pazarda satışı gerçekleştirilen ürünlerden numuneler alınarak İstanbul Üniversitesi Bitki ve Bitkisel Ürünler Uygulama ve Araştırma Merkezi’ne teslim edilecek.',
            order:"order-3 2xl:order-4 xl:order-4 lg:order-4"
        },
        {
            id:5,
            title:'Değerlendirme',
            description:'Yapılan kontroller ve analizlerin değerlendirilmesi sonucunda uygun görülen üreticiler “Topraktan Fileye Gıda İzleme Modeli”nde yer almaya hak kazanacaklar.',
            order:"order-5 2xl:order-8 xl:order-8 lg:order-8"
        },
        {
            id:6,
            title:'Veri Kayıt',
            description:'Üreticilerin kişisel bilgileri, arazi bilgileri, ürün bilgileri ve diğer bilgileri topraktan fileye veri kayıt paneline eklenerek QR kod bilgileri sistem üzerinden alınacak.',
            order:"order-6 2xl:order-7 xl:order-7 lg:order-7"
        },
        {
            id:7,
            title:'Eğitimler',
            description:'Kayıtlı üreticiler temel yeterlilik, gıda kalite ve gıda güvenliği eğitimleriyle desteklenecek.',
            order:"order-8 2xl:order-6 xl:order-6 lg:order-6"
        },
        {
            id:8,
            title:'Denetim',
            description:'Dönem dönem araziler ve ürünler kontrol edilerek, bilgilerin güncel kalması sağlanacak. ',
            order:"order-7 2xl:order-5 xl:order-5 lg:order-5"
        },
        {
            id:9,
            title:'İzlenebilir ve Güvenilir Gıda',
            description:'Tüketicilerin satın almak istedikleri ürünleri daha pazara gitmeden görebildikleri, gıda kalite ve güvenliği analizlerinin kontrol edilebildiği bir model İstanbullu üreticilerin ve tüketicilerin kullanımına sunulmuş olacaktır.',
            order:"order-9 2xl:order-9 xl:order-9 lg:order-9"
        }
    ]
    return(
        <div className="relative overflow-hidden 2xl:mt-32 xl:mt-32 lg:mt-32 mt-6 p-6 pt-10 2xl:pb-0 xl:pb-0 lg:pb-0 mb-0">
           <div className="flex justify-center items-center flex-col 2xl:mb-20 xl:mb-20 lg:mb-20 mb-6">
            <h1 className="text-components-title absolute 2xl:top-0 xl:top-0 lg:top-0 top-4 -z-[1] font-extrabold leading-relaxed 2xl:text-8xl xl:text-8xl lg:text-8xl text-[3rem] 2xl:mt-0 xl:mt-0 lg:mt-0">Diyagram</h1>
            <h1 className="text-components-title relative  font-extrabold leading-relaxed text-center 2xl:text-4xl xl:text-4xl lg:text-4xl text-2xl 2xl:mt-0 xl:mt-0 lg:mt-0">Model Yönetim Diyagramı</h1>
           {/* p'nin içerisine açıklama yazılabilir */}
            <p className=" text-components-title font-bold mt-6 text-center text-sm"></p>
           </div>
           <div className=" grid grid-cols-12 gap-4 2xl:mx-8 xl:mx-8 lg:mx-8 relative  z-10">
            {/* cirle 1 start */}
            <div className="2xl:top-10 xl:top-10 lg:top-10 2xl:-right-72 xl:-right-72 lg:-right-72 top-6 -right-36 circle 
             2xl:w-[400px] xl:w-[400px] lg:w-[400px]  2xl:h-[400px] xl:h-[400px] lg:h-[400px] w-[150px] h-[150px]
             absolute border-[2px] border-orange-500 -z-[1] rounded-full">
                        <div className="circle  2xl:w-[350px] xl:w-[350px] lg:w-[350px]  2xl:h-[350px] xl:h-[350px] lg:h-[350px] w-[125px] h-[125px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full">
                            <div className="circle 2xl:w-[300px] xl:w-[300px] lg:w-[300px]  2xl:h-[300px] xl:h-[300px] lg:h-[300px] w-[100px] h-[100px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full"></div>
                        </div>
            </div>
            {/* cirle 1 end */}
            {/* cirle 2 start */}
            <div className="2xl:bottom-0 xl:bottom-0 lg:bottom-0 -bottom-32 -left-0 circle 
             2xl:w-[200px] xl:w-[200px] lg:w-[200px]  2xl:h-[200px] xl:h-[200px] lg:h-[200px] w-[150px] h-[150px]
             absolute border-[2px] border-orange-500 -z-[1] rounded-full">
                        <div className="circle  2xl:w-[300px] xl:w-[300px] lg:w-[300px]  2xl:h-[300px] xl:h-[300px] lg:h-[300px] w-[125px] h-[125px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full">
                            <div className="circle 2xl:w-[250px] xl:w-[250px] lg:w-[250px]  2xl:h-[250px] xl:h-[250px] lg:h-[250px] w-[100px] h-[100px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full"></div>
                        </div>
            </div>
            {/* cirle 2 end */}
           {data.map((item,index) => (<DiagramItemComponent data={item} key={index}/>))}
           </div>
        </div>
    )
}

