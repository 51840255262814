import {ReactComponent as Next} from "../assets/next.svg"
import sanitizeHtml from 'sanitize-html';

export default function ContainerComponent({containerInformation}) {
    const sanitizeOptions = {
        allowedTags: ['b', 'i', 'em', 'strong', 'a','span'],
        allowedAttributes: {
          a: ['href'],
        },
      };
      
      const sanitizedTitle = sanitizeHtml(containerInformation.title, sanitizeOptions);
 
    return (
      <div className="2xl:pt-40 xl:pt-40 lg:pt-40 pt-20 2xl:ml-10 xl:ml-10 lg:ml-10 mx-4 2xl:px-10 xl:px-8 lg:px-6 px-0
     bg-opacity-95 pb-0">
            <div className="relative  grid grid-cols-12 ">
            <p
            className={`text-components-title absolute -z-[1]  w-full 2xl:text-[170px] xl:text-[170px] lg:text-[170px] text-6xl  flex justify-center items-end 2xl:-mt-14 xl:-mt-14 lg:-mt-14 mt-[17rem] font-extrabold 
             ${
                containerInformation.componentPosition === "left" 
                    ? "2xl:justify-start xl:justify-start lg:justify-start "
                    : containerInformation.componentPosition === "right" ?  "2xl:justify-end xl:justify-end lg:justify-end " :""
                }`} >{containerInformation.bgText}
            </p>
           <div className={`2xl:col-span-6 xl:col-span-6 lg:col-span-6 col-span-12 z-10 ${
                containerInformation.componentPosition === "left" 
                    ? "2xl:order-1 xl:order-1 lg:order-1 order-2"
                    : containerInformation.componentPosition === "right" ?  "2xl:order-2 xl:order-2 lg:order-2 order-2" :""
                }`}>
                <h1
                className={`text-components-title font-extrabold leading-relaxed text-center 2xl:text-[2.5rem] xl:text-[2.5rem] lg:text-[2.5rem] text-xl 2xl:mt-0 xl:mt-0 lg:mt-0 mt-20
                ${
                   containerInformation.componentPosition === "left" 
                       ? "2xl:text-start xl:text-start lg:text-start "
                       : containerInformation.componentPosition === "right" ?  "2xl:text-end xl:text-end lg:text-end ":""
                   }`} dangerouslySetInnerHTML={{ __html: sanitizedTitle }}></h1>
                <p 
                className={`text-components-title font-bold mt-9  2xl:text-lg xl:text-lg lg:text-lg text-sm  text-center 
                ${
                   containerInformation.componentPosition === "left" 
                       ? "2xl:text-start xl:text-start lg:text-start"
                       : containerInformation.componentPosition === "right" ?  "2xl:text-end xl:text-end lg:text-end ":""
                   }`}>{containerInformation.description}</p>
                <div 
                className={`flex 2xl:justify-start xl:justify-start lg:justify-start justify-center gap-x-4 items-center mt-10 
                ${
                   containerInformation.componentPosition === "left" 
                       ? "2xl:justify-start xl:justify-start lg:justify-start "
                       : containerInformation.componentPosition === "right" ?  "2xl:justify-end xl:justify-end lg:justify-end " :""
                   }`} >
                    {
                        containerInformation.closeButton !== true && 
                        <div className=''>
                           <a href="/about" className=' flex items-center gap-x-4'>
                            <button className="border-b-2  border-black text-orange-500  font-bold">DETAYLI BİLGİ AL</button>
                                <Next/>  
                           </a>
                        </div>  
                    }
                    
                </div>

            </div> 
            <div 
            className={`2xl:col-span-6 xl:col-span-6 lg:col-span-6 col-span-12 flex relative z-10  ${
                containerInformation.componentPosition === "left" 
                    ? "2xl:order-2 xl:order-2 lg:order-2 order-1 justify-end "
                    : containerInformation.componentPosition === "right" ?  "2xl:order-1 xl:order-1 lg:order-1 order-1 justify-start " :""
                }`}
            >
                <div className={`w-full flex justify-center  items-center ${containerInformation.componentPosition === "left" ? "2xl:justify-end xl:justify-end lg:justify-end" :  containerInformation.componentPosition === "right" ? "2xl:justify-start xl:justify-start lg:justify-start":""}`}>
                <div className="relative max-w-xl 2xl:w-10/12 xl:w-10/12 lg:w-10/12 w-full 2xl:h-80 xl:h-80 lg:h-72 h-52 rounded-lg px-20 ">
                    <div  
                    className={`absolute  w-1/2 h-[10rem] bg-img-background-box-primary rounded-lg  top-0  ${
                        containerInformation.componentPosition === "left" 
                            ? "right-0"
                            : containerInformation.componentPosition === "right" ?  "left-0 " :""
                        }`}/>
                    <div 
                    className={`absolute bottom-0 w-1/2 h-[10rem] bg-img-background-box-secondary rounded-lg ${
                        containerInformation.componentPosition === "left" 
                            ? "left-0 "
                            : containerInformation.componentPosition === "right" ?  "right-0 " :""
                        }`}/>
                    <div className="absolute inset-1/2 w-[90%] h-[85%] rounded-lg transform -translate-x-1/2 -translate-y-1/2">
                        <img src={containerInformation.img} alt="market" className="rounded-lg object-contain h-full w-full"/>
                    </div>
                        <div
                        className={`circle  2xl:w-[400px] xl:w-[400px] lg:w-[400px]  2xl:h-[400px] xl:h-[400px] lg:h-[400px]  w-[265px] h-[265px] absolute border-[2px] border-orange-500 -z-[1] rounded-full  ${
                            containerInformation.componentPosition === "left" 
                                ? "top-[50%] left-[20%] "
                                : containerInformation.componentPosition === "right" ?  "top-[50%]   2xl:left-[80%] xl:left-[80%] lg:left-[80%]    " :""
                            }`}>
                        <div className="circle  2xl:w-[350px] xl:w-[350px] lg:w-[350px]  2xl:h-[350px] xl:h-[350px] lg:h-[350px]  w-[235px] h-[235px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full">
                            <div className="circle 2xl:w-[300px] xl:w-[300px] lg:w-[300px]  2xl:h-[300px] xl:h-[300px] lg:h-[300px]  w-[200px] h-[200px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full"></div>
                        </div>
                        </div>
                </div>
                </div>
            </div>
            </div>
      </div>
    );
  }
  