import StarterComponent from "../components/StarterComponent";
import BoxComponent from "../components/BoxComponent";
import ContainerComponent from "../components/ContainerComponent";
import DiagramComponent from "../components/DiagramComponent";

import MobilePromotionComponent from "../components/MobilePromotionComponent"
import MapComponent from "../components/MapComponent";
import AnnounceComponent from "../components/AnnounceComponent";
import { useEffect,useState } from "react";
import config from "../services/config";

const ENDPOINT = "announcements"

export default function Home() {
  const boxDatas = [
    {
      id:1,
      title:'GIDADA İZLENİLEBİLİRLİK',
      description:'Güvenli gıdaya erişimde; üretim, işleme, taşıma, satış gibi aşamaların kontrol altında tutulduğu ve takip edilebildiği sistemdir.',     
    },
    {
      id:2,
      title:'AMAÇ',
      description:'İstanbul Büyükşehir Belediyesi tarafından 2020 yılında ilk kez “Kadıköy Üretici ve Kooperatif Pazarı” adıyla hayata geçirilen ve İstanbul’da tarımsal üretim yapan çiftçilerin ürünlerini, aracısız bir şekilde kendileri tarafından satışına olanak sağlayan üretici pazarı modelinde dijital ve izlenilebilir sistem kurmak.',     
    },
    {
      id:3,
      title:'HEDEF',
      description:'Topraktan Fileye Gıda İzleme Modeli’nin izlenebilirlik yönüyle diğer pazarlar için bir model olmasını sağlamak, tüketicilerin gıda seçimlerinde gıda kalitesi ve güvenliğini de ele alan kriterlere dikkat çekmelerini sağlamak.',     
    },
  ];
  const containerInformation = [
        {
          id:1,
          title: 'Tükettiğiniz Gıdanın Hikâyesini Bilmek İster Misiniz?”',
          description:
            'İstanbul Kalkınma Ajansı’nın 2021 yılı Yenilikçi İstanbul Mali Destek Programı kapsamında hibe almaya hak kazanan “Topraktan Fileye Gıda İzleme Modeli” ile filenize giren ürünlerin hikayesine ulaşmanız artık çok daha kolay. Model uygulama olarak Kadıköy Üretici ve Kooperatif Pazarında başlanan proje ile dijital dönüşüm ile gıda izlenebilirliği sağlanıyor.',
          img: '/why.png',
          bgText:'',
          componentPosition:'left',
        },
        {
          id:2,
          title: 'Proje Kimler Tarafından Ve Nasıl Yürütülüyor?',
          description:'İstanbul Kalkınma Ajansı’nın 2021 yılı Yenilikçi İstanbul Mali Destek Programı kapsamında hibe almaya hak kazanan “Topraktan Fileye Gıda İzleme Modeli” kapsamında hayata geçirilen projenin liderliği İstanbul Büyükşehir Belediyesi Tarımsal Hizmetler Dairesi Başkanlığı tarafından yürütülmekte, İstanbul Üniversitesi ise proje ortağı olarak yer almaktadır. Kadıköy Üretici ve Kooperatif Pazarı’nın işletmesini yapan İSYÖN A.Ş. ise proje iştiraki olarak projede yer almaktadır. Projenin dijital dönüşüm, arazi kontrol, yazılım, donanım, bilgi işlem, arazi kontrol, veri kayıt ve işleme, denetleme, yönetim, yürütme ve sonuçlandırma işleri İstanbul Büyükşehir Belediyesi uhdesinde gerçekleştirilmektedir.',
          img: '/objective.png',
          bgText:'',
          componentPosition:'right',
        },
  ];

  const apiRequest = async (params) => {
    const response = await fetch(`${config.API_URL}/${ENDPOINT}?AnnouncementType=${params}`);
    
    if (!response.ok) {
      throw new Error('API request failed');
    }
    
    const data = await response.json();
    return data.data;
  };
  const [announcementsData, setAnnouncementsData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);

  useEffect(() => {
    apiRequest('1')
      .then((data) => {
        setNewsData(data);
      })
      .catch((error) => {
        console.error('News API request failed:', error);
      });
  
    apiRequest('2')
      .then((data) => {
        setAnnouncementsData(data);
      })
      .catch((error) => {
        console.error('Announcements API request failed:', error);
      });
  
    apiRequest('3')
      .then((data) => {
        setActivitiesData(data);
      })
      .catch((error) => {
        console.error('Activities API request failed:', error);
      });
  }, []);

  useEffect(() => {
    if (!window.location.href.includes("#")) {
      const element = document.getElementById('root'); 
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  
  return (
    <>
        <StarterComponent/>
        <div className="flex flex-col px-2 gap-4 mx-2 2xl:flex-row  xl:flex-row lg:flex-row  2xl:-mt-10 xl:-mt-10 lg:-mt-6 mt-10 2xl:mx-10 xl:mx-10  lg:mx-10">
         {boxDatas.map((item,index) => (<BoxComponent title={item.title} description={item.description} key={index}></BoxComponent> ))}
        </div>
        <div id="about">{containerInformation.map((item,index) => (<ContainerComponent containerInformation={item} key={index}></ContainerComponent>))}</div>
        <DiagramComponent/>
        <AnnounceComponent data={announcementsData} announcementType={"2"} shadowText={"Duyurular"} title={"Proje Duyuraları"} description={""}/>
        <MobilePromotionComponent/>
        <AnnounceComponent data={newsData} shadowText={"Haberler"} title={"Proje Haberleri"} description={""}/>
        <MapComponent/>
        <AnnounceComponent data={activitiesData} shadowText={"Etkinlikler"} title={"Proje Etkinlikleri"} description={""}/>
    </>
  );
}
