import React from 'react'

export default function SssCloseComponent({color,textColor}) {
  return (
    <div className="cursor-pointer">
     <svg className='2xl:w-10 xl:w-10 lg:w-10 w-6 ' viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="41" height="41" rx="5" fill={color}/>
      <path d="M24.9677 29.9615L20.2835 24.1215L16.0335 29.9615H11.1321L17.9568 21.2139L11.0391 12.6154H16.0335L20.7178 18.4305L24.9367 12.6154H29.8381L23.0444 21.3381L29.9621 29.9615H24.9677Z" fill={textColor}/>
    </svg>
    </div>
  )
}