import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {ReactComponent as TopraktanFileye} from '../assets/Logos/topraktan-fileye.svg'

export default function StarterComponent() {
const farmerImage='/farmer-image.png';
const fruitsImage='/fruits.png';
const kioskUrl="https://gim.topraktanfileye.istanbul/"; 
const location = useLocation();  
useEffect(() => {
  const hashValue = location.hash.substring(1);
  if (hashValue) {
      const goElement = document.getElementById(`${hashValue}`);
        if (goElement) {
          goElement.scrollIntoView({ behavior: 'smooth' });
        }  
    }
  }, []);



  return (
<section className='relative'>
      <div className="2xl:h-screen xl:h-screen lg:h-screen h-[140%] w-full absolute index-bg flex flex-col -mt-[12vh]"></div> 
      <div className="w-full  2xl:h-[88vh] xl:h-[88vh] lg:h-[88vh] h-full  grid grid-cols-12">
          <div className="col-span-12 order-2 
          2xl:pt-16 2xl:order-1   2xl:col-span-7
          xl:pt-16 xl:ml-20      xl:order-1      xl:col-span-8
          lg:pt-8  lg:ml-14      lg:order-1      lg:col-span-8
          md:pt-0 
          sm:pt-0
          2xl:-mt-0 xl:-mt-0 lg:-mt-0 md:-mt-0 -mt-24 
          ">
                        <div className="flex 2xl:gap-10 xl:gap-10 lg:gap-10 md:gap-8 gap-2 items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center ">
              <TopraktanFileye className="
                2xl:w-[130px] 2xl:h-[130px]
                xl:w-[130px] xl:h-[130px]
                lg:w-[130px lg:h-[130px]
                md:w-[100px] md:h-[100px]
                w-[90px] h-[90px]
              "/>
          <div>
          <h1 className=" 
            text-index-title font-extrabold
            2xl:text-[3rem] 2xl:text-start 2xl:mt-0
            xl:text-[2.5rem] xl:text-start xl:mt-0
            lg:text-3xl lg:text-start  lg:mt-0
            md:text-4xl md:text-center md:mt-10
            sm:text-4xl sm:text-center sm:mt-10
            text-xl text-center mt-4 
            ">TOPRAKTAN FİLEYE</h1>
            <h2 className=" font-extrabold text-index-subtitle mt-0  text-center
            2xl:text-3xl 2xl:text-start 2xl:mt-4
            xl:text-[1.5rem] xl:text-start xl:mt-4
            lg:text-xl lg:text-start lg:mt-4
            md:text-2xl md:text-center md:mt-0
            sm:text-2xl sm:text-center sm:mt-0
            text-lg 2xl:mb-8 xl:mb-6 lg:mb-0 mb-6
            ">GIDA İZLEME MODELİ</h2>
          </div>
           </div>
            <p className="font-bold mt-4 text-center text-xs px-4 leading-normal 2xl:w-[90%] xl:w-[90%] lg:w-[90%] 
              2xl:text-lg  2xl:text-start 2xl:mt-10
              xl:text-lg xl:text-start xl:mt-10
              lg:text-md lg:text-start lg:mt-4
              md:text-sm md:text-center md:mt-4
              sm:text-xs sm:text-center sm:mt-4
              text-components-title
            "><span className="font-bold">Tükettiğiniz Gıdanın Hikâyesini Bilmek İster Misiniz?</span><br></br><br></br>
            İstanbul Kalkınma Ajansı’nın 2021 yılı Yenilikçi İstanbul Mali Destek Programı kapsamında hibe almaya hak kazanan “Topraktan Fileye Gıda İzleme Modeli” ile filenize giren ürünlerin hikayesine ulaşmanız artık çok daha kolay. Model uygulama olarak Kadıköy Üretici ve Kooperatif Pazarında başlanan proje ile dijital dönüşüm ile gıda izlenebilirliği sağlanıyor.</p>
            <div className="mt-4 lg:mt-4 xl:mt-0 2xl:flex 2xl:flex-row xl:flex xl:flex-row lg:flex lg:flex-row  flex flex-col  gap-x-6 gap-y-2 ">
            <a href="/about" className='flex justify-center
            text-center font-semibold   text-white bg-index-subtitle rounded-md
            2xl:text-lg 2xl:py-[.75rem] 2xl:px-10 2xl:mx-0 2xl:mt-10
            xl:text-lg xl:py-[.5rem] xl:px-10 xl:mx-0 xl:mt-10
            lg:text-lg lg:py-2 lg:px-6 lg:mx-0 lg:mt-2
            md:text-lg 
            sm:text-sm
            text-xs
            py-4 px-6
                mx-10'>
              <button className="font-bold uppercase 
                ">DETAYLI BİLGİ AL</button>
            </a>
            <a href={kioskUrl} target="_blank"  className='flex justify-center text-white bg-index-title rounded-md
                 2xl:text-lg 2xl:py-[.75rem] 2xl:px-10 2xl:mx-0 2xl:mt-10
                 xl:text-lg xl:py-[.5rem] xl:px-10 xl:mx-0 xl:mt-10
                 lg:text-lg lg:py-2 lg:px-6 lg:mx-0 lg:mt-2
                 md:text-lg 
                 sm:text-sm
                 text-xs
                py-4 px-6
                mx-10 text-center' rel="noreferrer">
                <button className=" uppercase font-semibold   
                ">UYGULAMAYA GİT</button>
              </a>
            </div>
          </div>
          <div className=" order-1 w-full h-full col-span-12 mb-60
          2xl:order-2 2xl:col-span-5 2xl:p-0
          xl:order-2  xl:col-span-4 xl:p-0
          lg:order-2  lg:col-span-4 lg:p-0 z-10
          ">
          <div className="2xl:w-full w-2/3 lg:w-full xl:w-full md:w-1/2 m-auto 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-10 sm:mt-10 mt-0 h-full relative overflow-hidden flex justify-start items-start">
          <img
            src={fruitsImage}
            alt="farmer"
            className="h-full 2xl:absolute xl:absolute lg:absolute -top-14 2xl:mt-0 xl:mt-0 lg:mt-0 md:-mt-20 sm:-mt-20 -mt-7"
            style={{ maxWidth: "100%" ,objectFit:"contain"}}
          />
          </div>
          </div>
     </div> 
</section>
  )
}