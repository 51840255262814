import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';


export default function LinkItemComponent({ data, isMobile, optionsClick }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  function navElementClick() {
    if (data.src!=="/") {
      if (location.pathname ==="" || location.pathname==="/") {
        const element = document.querySelector(`${data.src}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          navigate(`/${data.src}`);
        }  
    }else{
      navigate(`/${data.src}`);
    }
    }
    else{
      const url = location.pathname;
      if (url.length>1) {
        navigate(`/`);
      }
      else{
          navigate("/")  
          const element = document.getElementById(`root`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
      }
    }
    
  }
  useEffect(() => {
    if (
      (window.location.href.split("/")[3].length<2 && data.src==="/") ||
      data.src.includes(window.location.href.split("#")[1])||
      (data.title === 'Hakkımızda' && location.pathname === '/about') ||
      (data.title === 'Sıkça Sorulan Sorular' && location.pathname === '/sss')
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [data.src, data.title, location]);

  return (
    <div onClick={navElementClick} className="h-full flex items-center relative justify-center">
      {active && data?.isHeader && !isMobile && <div className="absolute top-0 w-full active"></div>}
      <li onClick={isMobile && optionsClick} className={`${active ? 'text-[#408320]' : ''} list-none font-bold`}>
        {data.title}
      </li>
    </div>
  );
}
