import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CustomLeftArrow from "./CustomLeftArrow";
import CustomRightArrow from "./CustomRightArrow";
import SliderItemComponent from './SliderItemComponent';

export default function SliderComponent({ data = [], closeText, files ,clickable,detailSlider}) {
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide, totalItems } } = rest;
    const showArrows = totalItems > 3;

    return (
      <div className="carousel-button-group">
        {showArrows && (
          <>
            <CustomLeftArrow
              className={currentSlide === 0 ? 'disable' : ''}
              onClick={() => previous()}
            />
            <CustomRightArrow onClick={() => next()} />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="2xl:mx-40 xl:mx-40 lg:mx-40 mx-10 pt-20 flex items-center justify-center relative">
      <Carousel
        additionalTransfrom={0}
        autoPlaySpeed={3000}
        centerMode={false}
        className="relative"
        containerClass="container"
        arrows={false}
        customButtonGroup={<ButtonGroup />}
        dotListClass=""
        draggable={false}
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 3,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
          }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {data?.files && files
          ? data?.files?.map((item, index) => (
              <SliderItemComponent
                detailSlider={detailSlider}
                closeText={closeText}
                images={item?.id}
                data={item}
                key={index}
              />
            ))
          : data?.map((item, index) => (
              <SliderItemComponent 
                detailSlider={detailSlider}
                clickable={clickable}
                closeText={closeText}
                data={item}
                images={item?.mainPictureId}
                key={index}
              />
            ))}
      </Carousel>
    </div>
  );
}
