import './styles/globals.css';
import AppRouter from './AppRouter';

export default function App() {
  return(
          <div id='bg-opacity' className="h-full bg-white -z-[2] flex flex-col justify-between overflow-x-hidden">
              <AppRouter/>
          </div>
        
  )
}
