import { Link } from "react-router-dom"
export default function MapComponent() {
  return (
   <div className="py-6 grid grid-cols-12 bg-img-background-box-secondary items-center w-full 2xl:text-start xl:text-start  lg:text-start  text-center 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-10 px-2 mb-20"> 
   <div className="2xl:col-span-6 xl:col-span-6 lg:col-span-6 col-span-12 flex flex-col 2xl:px-6 xl:px-6 lg:px-6">
      <h1 className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-2xl text-xl text-white font-extrabold !leading-snug">Qr Kod ile Pazarımızın Yol Tarifini Alın</h1>
  
    <p className="text-white font-medium 2xl:text-2xl xl:text-xl lg:text-lg text-sm mt-6 2xl:pr-10 xl:pr-10 lg:pr-10 2xl:px-0 xl:px-0 lg:px-0 px-6">Pazar lokasyonuna ulaşabilmek için QR Kod okut ya da “Yol Tarifi Al” tıkla.</p>
    <Link className="w-full" to="https://www.google.com/maps?opi=89978449&lqi=ChZrYWTEsWvDtnkgaGFsayBwYXphcsSxSMTTguKeqoCACFoiEAEQAhgAGAEYAiIWa2FkxLFrw7Z5IGhhbGsgcGF6YXLEsZIBBm1hcmtldJoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VSNU5DMTZTRVpCRUFFqgFREAEqECIMaGFsayBwYXphcsSxKCEyHxABIhuyyPG-S712kVp-lpETxPBc8XzZHDLXwnmWJLEyGhACIhZrYWTEsWvDtnkgaGFsayBwYXphcsSx&vet=12ahUKEwi0hbGCt-P-AhUKX_EDHUvFAr4Q8UF6BAgUEFU..i&lei=RrdXZLTKEYq-xc8Py4qL8As&cs=1&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KdsY07b1x8oUMRN2oDVzho58&daddr=Hasanpa%C5%9Fa,+Mand%C4%B1ra+Cd.,+34722+Kad%C4%B1k%C3%B6y/%C4%B0stanbul" target="_blank">
    
    <button className="uppercase font-semibold   text-white bg-index-subtitle rounded-md
                2xl:text-lg 2xl:py-4 2xl:px-10 2xl:mx-0 2xl:mt-10 
                xl:text-lg xl:py-4 xl:px-10 xl:mx-0 xl:mt-10
                lg:text-md lg:py-2 lg:px-6  lg:mx-0 lg:mt-10
                md:text-sm 
                sm:text-sm text-xs
                py-4 px-6 mx-10 my-4
                2xl:w-1/2 xl:w-1/2 xlg:w-1/2 w-[75%]
                ">
    YOL TARİFİ AL
    </button>
    </Link>
   </div>
   <div className="2xl:col-span-6 xl:col-span-6 lg:col-span-6 col-span-12 2xl:gap-x-0 xl:gap-x-0 lg:gap-x-3 place-items-center grid grid-cols-12 place-content-center items-center">
      <div className="col-span-6 2xl:flex xl:flex lg:flex hidden items-center h-full 2xl:-mr-44 bg-white rounded-lg">
      <img src={"/qr.png"} alt="qrcode" width={235} height={235}></img>
      </div>
      <div className="flex items-center 2xl:col-span-6 xl:col-span-6 lg:col-span-6 col-span-12 justify-center">
      <iframe
        src="
        https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.273117271174!2d29.045943775937!3d40.997394971352065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7f5b6d318db%3A0x7c8e867335a07613!2sTarihi%20Sal%C4%B1%20Pazar%C4%B1%202!5e0!3m2!1str!2str!4v1683545999868!5m2!1str!2str
        "
        className="h-[230px] w-[230px]   flex  justify-center items-center rounded-xl"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
        title="map"
      />
   </div>  
    </div>
   </div>
  )
}

