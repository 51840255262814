import {ReactComponent as IstanbulUniLogo} from '../assets/Logos/istanbul-uni-logo.svg'
import {ReactComponent as IbbLogoWhite} from '../assets/Logos/ibb-logo-white.svg'
import {ReactComponent as KalkinmaAjansiLogoWhite} from '../assets/Logos/kalkinma-ajansi-white.svg'
import {ReactComponent as SanayiBakanligiLogoWhite} from '../assets/Logos/sanayi-bakanligi-logo-white.svg'
import {useLocation} from "react-router-dom";
import { useEffect, useState } from 'react'
import LinkItemComponent from './LinkItemComponent'
import FollowUsComponent from './FollowUsComponent'
import AddressComponent from './AddressComponent'
import { Link } from 'react-router-dom';

export default function FooterComponent() {
    const location = useLocation();
    const nav =[
        {
          id:1,
          title:"Anasayfa",
          src:"/"
        },
        {
          id:2,
          title:"Hakkımızda",
          src:"#about"
        },
        {
          id:3,
          title:"Duyurular",
          src:"#announcements"
        },
        {
          id:4,
          title:"Haberler",
          src:"#news"
        }
      ];
    const [active, setActive] = useState(false);
  
    useEffect(() => {
      if (location.pathname === "/") {
          setActive(true);
      } else {
          setActive(false);
      }
  }, [location.pathname]);

    const addresses=[{
        title:"Proje Yönetim Ofisi",
        address:"İstanbul Büyükşehir Belediyesi Tarımsal Hizmetler Dairesi Başkanlığı Kocatepe Mah. Hal Yolu Sk. No:558/1 (Meyve Sebze Hali Kompleksi) Bayrampaşa / İSTANBUL",
        phone:"0(212) 449 92 00"
    },
    {
      title:"Gıda Güvenliği ve Kalite Analizleri",
      address:"İstanbul Üniversitesi Bitki ve Bitkisel Ürünler Uygulama ve Araştırma Merkezi, Beyazıt, 34126, Fatih/İSTANBUL",
      phone:"+90 0212 440 00 00"
    },
    {
      title:"İBB Üretici ve Kooperatif Pazarı",
      address:"Tarihi Kadıköy Antika ve Nostalji Pazarı Hasanpaşa, 34722 Kadıköy/İSTANBUL",
      phone:"+90 0212 440 00 00"
    }
  ]
  return (
    <footer className="grid grid-cols-12 bg-footer-bg w-full text-white">
        <div className='2xl:col-span-4 xl:col-span-4 lg:col-span-4 col-span-12 p-10 2xl:text-start xl:text-start lg:text-start text-center'>
            <div className='flex gap-6 pb-6 2xl:justify-start xl:justify-start lg:justify-start justify-center'>
                             <Link to="https://www.sanayi.gov.tr/anasayfa" target='_blank' className=''><SanayiBakanligiLogoWhite/></Link>
                             <Link to="https://www.istka.org.tr/" target='_blank' className=' '><KalkinmaAjansiLogoWhite className=""/></Link>
                             <Link to="https://tarim.ibb.istanbul/" target='_blank'><IbbLogoWhite className="w-[40px] h-[40px] "/></Link>
                             <Link to="https://bibum.istanbul.edu.tr/tr/_"  target='_blank'><IstanbulUniLogo className="w-[40px] h-[35px] "/></Link>
            </div>
            <h4 className="text-2xl font-bold mt-4 mb-8">“Topraktan Fileye  Gıda İzleme Modeli”</h4>
            <p className="text-sm font-normal">
                
            Bu web sitesi, İstanbul Kalkınma Ajansı’nın desteklediği &ldquo;Topraktan Fileye Gıda İzleme Modeli&ldquo; Projesi kapsamında hazırlanmıştır. İçerik ile ilgili tek sorumluluk İstanbul Büyükşehir Belediyesi’ne ait olup İSTKA veya Sanayi ve Teknoloji Bakanlığı’nın görüşlerini yansıtmamaktadır.”  
            </p>
            <FollowUsComponent ></FollowUsComponent>
            
        </div>
        <div className='2xl:col-span-4 xl:col-span-4 lg:col-span-4 col-span-12 b flex flex-col relative items-center justify-center  py-10 gap-6 font-bold text-xl'>
            <div className='h-full w-[1px] py-8 flex items-center absolute left-16'><div className='h-full  w-full flex items-center  2xl:bg-white xl:bg-white lg:bg-white bg-transparent'></div></div>
            <div className='h-[1px] w-full px-8 flex items-center absolute top-0 left-0'><div className='h-full  w-full flex items-center  2xl:bg-transparent xl:transparent lg:transparent bg-white 2xl:hidden xl:hidden lg:hidden'></div></div>
            <ul className='flex flex-col gap-y-8 h-full text-center '>
                        {nav.map((item,index) => (<LinkItemComponent data={item} key={index}></LinkItemComponent>))}
                        <Link to='/sss'><li className={`${location.pathname==="/sss" ? "text-green-600" : ""}`}>S.S.S</li></Link>
            </ul>
            <div className='h-full w-[1px] py-8 flex items-center absolute right-16'><div className='h-full  w-full flex items-center  2xl:bg-white xl:bg-white lg:bg-white bg-transparent'></div></div>
            <div className='h-[1px] w-full px-8 flex items-center absolute bottom-0 left-0'><div className='h-full  w-full flex items-center  2xl:bg-transparent xl:transparent lg:transparent bg-white 2xl:hidden xl:hidden lg:hidden'></div></div>

        </div>
        <div className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 col-span-12 flex 2xl:justify-end xl:justify-end lg:justify-end justify-center  ">
            <div className='flex flex-col 2xl:text-end xl:text-end lg:text-end text-center px-10 py-16 gap-6'>
                {addresses.map((item,index)=>{return <AddressComponent title={item.title} address={item.address} phone={item.phone} key={index}></AddressComponent>})}
            </div>
        </div>
    </footer>
  )
}

