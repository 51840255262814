import { useEffect, useState } from 'react'
import {ReactComponent as IbbLogo} from '../assets/Logos/ibb-logo.svg'
import {ReactComponent as IstanbulUniLogo} from '../assets/Logos/istanbul-uni-logo.svg'
import {ReactComponent as SanayiBakanligiLogo} from '../assets/Logos/sanayi-bakanligi-logo.svg'
import {ReactComponent as TopraktanFileye} from '../assets/Logos/topraktan-fileye.svg'
import {ReactComponent as Options} from '../assets/options.svg'
import Close from "../components/SssCloseComponent"
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import LinkItemComponent from './LinkItemComponent'

export default function Header() {
  const [clickable,setClickable] = useState(false);
  const optionsClick = () => {
    setClickable(!clickable);
  }
  const location = useLocation();
  const nav =[
    {
      id:1,
      isHeader:true,
      title:"Anasayfa",
      src:"/"
    },
    {
      id:2,
      isHeader:true,
      title:"Hakkımızda",
      src:"#about"
    },
    {
      id:3,
      isHeader:true,
      title:"Duyurular",
      src:"#announcements"
    },
    {
      id:4,
      isHeader:true,
      title:"Haberler",
      src:"#news"
    },
    {
      id:5,
      isHeader:true,
      title:"Etkinlikler",
      src:"#activity"
    },
  ];
     const [active, setActive] = useState(false);
      useEffect(() => {
         if (location.pathname === "/") {
         setActive(true);
         }
         else{
         setActive(false);
        } 
       }
      , [location.pathname]);

//  <div className='2xl:mb-[12vh] xl:mb-[12vh] lg:mb-[12vh] mb-[21.5vh] p-0'>
  return(
<div className='2xl:mb-[12vh] xl:mb-[12vh] lg:mb-[12vh] p-0'>
<header id='header' className={`bg-white flex items-center justify-between shadow-md ${clickable ? "justify-center" : ""} 2xl:h-[12vh] xl:h-[12vh] lg:h-[12vh] 2xl:rounded-b-[3rem] xl:rounded-b-[3rem] lg:rounded-b-[3rem] rounded-b-[2rem]`}>
        { clickable === false &&
            <div className="
            flex 
            2xl:ml-8 2xl:gap-x-10
            xl:ml-8 xl:gap-x-10
            lg:ml-6 lg:gap-x-8
            ml-0 gap-x-4
            py-8 px-4
          ">
             <Link to="https://www.sanayi.gov.tr/anasayfa" target='_blank'>
             <SanayiBakanligiLogo className="
                  2xl:w-[52px] 2xl:h-[52px]
                  xl:w-[50px] xl:h-[52px]
                  lg:w-[40px] lg:h-[52px]
                  md:w-[40px] md:h-[52px]
                  w-[25px] h-[35px]
                "/>
            </Link>
            <Link to="https://www.istka.org.tr/" target='_blank'>
                <img src='/Logos/IstanbulKalkinmaAjansiTR.svg' className="
                  2xl:w-[78px] 2xl:h-[52px]
                  xl:w-[60px] xl:h-[52px]
                  lg:w-[50px] lg:h-[52px]
                  md:w-[45px] md:h-[52px]
                  sm:w-[35px] sm:h-[35px]
                  w-[35px] h-[35px]
                "/>
            </Link>
            <Link to="https://tarim.ibb.istanbul/" target='_blank'>
              <IbbLogo className="
                2xl:w-[52px] 2xl:h-[52px]
                xl:w-[52px] xl:h-[52px]
                lg:w-[52px lg:h-[52px]
                md:w-[40px] md:h-[52px]
                w-[25px] h-[35px]
              "/>
            </Link>
            <Link to="https://bibum.istanbul.edu.tr/tr/_" target='_blank'>
              <IstanbulUniLogo className="
                2xl:w-[52px] 2xl:h-[52px]
                xl:w-[52px] xl:h-[52px]
                lg:w-[40px] lg:h-[52px]
                md:w-[40px] md:h-[52px]
                w-[25px] h-[35px]
                "/>
            </Link>
            {/* <Link to="/">
                <TopraktanFileye className="
                2xl:w-[52px] 2xl:h-[52px]
                xl:w-[50px] xl:h-[52px]
                lg:w-[40px] lg:h-[52px]
                md:w-[40px] md:h-[52px]
                w-[25px] h-[35px]"/>
              </Link> */}
            </div>
        }
          <div className=' h-full flex items-center '>
            <div className="flex  2xl:hidden xl:hidden lg:hidden p-4 ">
              <button onClick={optionsClick}>
                {
                  clickable === false &&
                  <Options></Options>
                }
              </button>
            </div>
            <div className="items-center h-full cursor-pointer 2xl:flex xl:flex lg:flex md:hidden sm:hidden hidden font-semibold 2xl:text-2xl xl:text-2xl lg:text-lg  gap-x-10 mr-8">
                        {nav.map((item,index) => (<LinkItemComponent data={item} key={index}></LinkItemComponent>))}
            </div>
          </div>          
              {
                clickable === true &&
                <div className={`2xl:hidden xl:hidden lg:hidden absolute top-10 left-7 sm:left-14 z-20  flex flex-col w-[85%]  bg-white h-auto px-4 shadow-2xl pt-2 pb-10 `}>
                     <div className='flex items-center justify-between w-full'>
                  <div className="
                    flex  items-center h-full w-full
                    2xl:ml-8 2xl:gap-x-10
                    xl:ml-8 xl:gap-x-10
                    lg:ml-6 lg:gap-x-8
                    ml-0 mr-4 gap-x-2
                  ">
                     <Link to="https://www.sanayi.gov.tr/anasayfa"target='_blank' onClick={optionsClick}>
                <SanayiBakanligiLogo className="
                      2xl:w-[52px] 2xl:h-[52px]
                      xl:w-[52px] xl:h-[52px]
                      lg:w-[52px] xlgh-[52px]
                      md:w-[52px] xmdh-[52px]
                      w-[25px] h-[35px]
                    "/></Link>
                
             
              <Link to="https://www.istka.org.tr/" target='_blank' onClick={optionsClick}>
                <img src='/Logos/IstanbulKalkinmaAjansiTR.svg' className="
                      2xl:w-[52px] 2xl:h-[52px]
                      xl:w-[52px] xl:h-[52px]
                      lg:w-[52px] lg:h-[52px]
                      md:w-[52px] md:h-[52px]
                      w-[32px] h-[35px] 
                    "/></Link>
                <Link to="https://tarim.ibb.istanbul/" target='_blank' onClick={optionsClick}>
                  <IbbLogo className="
                    2xl:w-[52px] 2xl:h-[52px]
                    xl:w-[52px] xl:h-[52px]
                    lg:w-[52px lg:h-[52px]
                    md:w-[40px] md:h-[52px]
                    w-[25px] h-[35px]
                  "/>
                </Link>
                 <Link to="https://bibum.istanbul.edu.tr/tr/_" target='_blank' onClick={optionsClick}>
                  <IstanbulUniLogo className="
                    2xl:w-[52px] 2xl:h-[52px]
                    xl:w-[52px] xl:h-[52px]
                    lg:w-[40px] lg:h-[52px]
                    md:w-[40px] md:h-[52px]
                    w-[25px] h-[35px]
                  "/>
                </Link>
                {/* <Link to="/" onClick={optionsClick}>
                <TopraktanFileye className="
                  2xl:w-[52px] 2xl:h-[52px]
                  xl:w-[50px] xl:h-[52px]
                  lg:w-[40px] lg:h-[52px]
                  md:w-[40px] md:h-[52px]
                  w-[25px] h-[35px]
                "/>
                        </Link> */}
                        </div>
                        <button onClick={optionsClick}><Close color="rgba(0, 67, 140, 1)" textColor={"white"}></Close></button>
                     </div>
                     <div className='text-center flex flex-col justify-center items-center pt-2'>
                      <div className='w-full h-[1px] my-6 bg-black'/>
                      <ul className="cursor-pointer flex flex-col font-semibold text-2xl gap-6">
                        {nav.map((item,index) => (<LinkItemComponent data={item} key={index} isMobile={true} clickable={clickable} optionsClick={optionsClick}></LinkItemComponent>))}
                      </ul>
                     </div>
                </div>
              }
            
        </header>
</div>
    )
}