import { useEffect } from "react"
import SssItemComponent from "../components/SssItemComponent"
export default function SssPage() {
  const items = [
        {
          id:1,
          title:"Üreticiyim, Topraktan Fileye Gıda İzleme Modelinde nasıl yer alabilirim?",
          description:"Topraktan Fileye Gıda İzleme Modelinde yer alabilmeniz için üretim yaptığınız arazinin ve ikametinizin İstanbul sınırları içerisinde yer alması ve çiftçiliğinizi belgelemeniz (ÇKS belgesi, Ziraat Odası Çiftçi Belgesi vb.) gerekmektedir.",
        },
        {
          id:2,
          title:"Hangi pazarlarda Topraktan Fileye Gıda İzleme Modeli uygulanmaktadır?",
          description:"İstanbul Büyükşehir Belediyesinin sorumluluğunda 2 adet pazar yer almaktadır. Bunlardan ilki 2020 yılında açılışı gerçekleştirilen Kadıköy Üretici ve Kooperatif Pazarı, diğeri ise Beşiktaş Ulus Pazarıdır. Projenin İstanbul Kalkınma Ajansı’na başvurulduğu tarihte Ulus Pazarının henüz kurulmamış olması nedeniyle sadece Kadıköy Üretici ve Kooperatif Pazarı kapsama alınmıştır. Ancak ilerleyen tarihlerde üretici ve tüketici talepleri doğrultusunda yeni, üretici ve kooperatif pazarları açılabilecektir.",
        },
        {
          id:3,
          title:"Kadıköy Üretici ve Kooperatif Pazarı ne zaman kurulmaktadır?",
          description:"İstanbullu üreticilerimiz her pazar sizlerle tarihi Kadıköy Salı pazarında kurulmaktadır. Mevsimsel koşullara göre pazara gelen ürün sayılarında değişiklikler olabilmektedir.",
        },
        {
          id:4,
          title:"Online satış olanağı mevcut mu?",
          description:"Şu aşamada kontrol ve denetim işlemleri nedeniyle ayrıca yasal mevzuat çerçevesinde satış işlemleri online olarak gerçekleştirilememektedir.",
        },
      {
          id:5,
          title:"Sipariş verebilir miyim?",
          description:"Pazar günleri pazarda çiftçilerle doğrudan görüşerek ürünler hakkındaki görüşlerinizi ve ürün taleplerinizi iletebilirsiniz. Ancak şu aşamada sistem üzerinden sipariş alınamamaktadır.",
        },
  ] 
  useEffect(() => {
    const element = document.getElementById('root'); 
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <div className="w-full h-full ">
        <div className='w-full flex items-center justify-center relative h-[35vh] px-4'>
         <div className=" h-full w-full absolute about-bg bg-opacity-50 -mt-24 flex flex-col"></div> 
              <h1 className='2xl:text-6xl xl:text-6xl lg:text-6xl text-3xl  font-extrabold text-center text-components-title'>Sıkça Sorulan Sorular</h1>
        </div>
        <div className="2xl:mx-10 xl:mx-10 lg:mx-10 md:mx-10 mx-2 mb-16">
          {items.map((item,index) => (<SssItemComponent data={item} key={index}></SssItemComponent>))}
        </div>
        
    </div>  
  )
}