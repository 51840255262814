export default function Box({title,description}){
    return(
        <div className="pt-10 bg-info-card-bg px-4 rounded-2xl z-10 2xl:w-[33%] xl:w-[33%] lg:w-[33%]">
            <div className="flex justify-between items-center">
                <div className="h-[1px] w-[20%] bg-black mx-4"></div>
                <p className="font-bold 2xl:text-3xl xl:text-2xl lg:text-xl text-lg uppercase text-components-title text-center">{title}</p>
                <div className="h-[1px] w-[20%] bg-black mx-4"></div>
            </div>
            <div className="mt-8 my-14 mx-4 leading-relaxed font-bold 2xl:text-xl xl:text-lg lg:text-lg text-sm text-center">
                <p className="text-components-title font-semibold">{description}
                </p>
            </div>
        </div>
    )
}